import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const BannerSlider = ({ strapiData }) => {

    return (
        <div className={styles.dedicatedBanner}>
            <Container className={styles.innerDedicated}>
                <div className={styles.dedicated}>
                <Container className={styles.contentWrapper}>
                        <div className={styles.dedicatedHeadingStatic}>
                            <h1
                                className={styles.subTitle}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.title,
                                }}
                            />
                            <h2
                                className={styles.bannerHeading}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.subTitle,
                                }}
                            />
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: strapiData?.description?.description,
                                }}
                            />
                        </div>
                        <Link to={strapiData?.buttons?.[0]?.url} className="text-decoration-none">
                            <div className={`blackButtonBlueArrowBg ${styles.btne}`}>
                                {strapiData?.buttons[0] && (
                                    <p>{strapiData?.buttons?.[0]?.title}</p>
                                )}
                            </div>
                        </Link>
                </Container>
                </div>

            </Container>
            <img
                className={styles.bannerCurve}
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
                alt='curve'
            />

        </div>
    )
}

export default BannerSlider