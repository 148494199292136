// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--e19dc";
export var backgroundImg = "Banner-module--backgroundImg--ef2cf";
export var bannerCurve = "Banner-module--bannerCurve--01f51";
export var bannerHeading = "Banner-module--bannerHeading--dc34b";
export var btn = "Banner-module--btn--c84e2";
export var btne = "Banner-module--btne--5093f";
export var contentWrapper = "Banner-module--contentWrapper--67dd3";
export var curve = "Banner-module--curve--16037";
export var dedicated = "Banner-module--dedicated--bcb1f";
export var dedicatedBanner = "Banner-module--dedicatedBanner--146f2";
export var dedicatedHeading = "Banner-module--dedicatedHeading--5295f";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--07d46";
export var description = "Banner-module--description--2a401";
export var innerDedicated = "Banner-module--innerDedicated--3c356";
export var newBtn = "Banner-module--newBtn--beba1";
export var sliderButtons = "Banner-module--sliderButtons--734aa";
export var subTitle = "Banner-module--subTitle--02277";